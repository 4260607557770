<template>
  <f7-page class="article-page" infinite :infinite-distance="50" :infinite-preloader="showPreloader" @infinite="loadMore">
    <template #fixed>
      <CommonNavigationComponent type="back" :title="$t.getTranslation('LBL_EVENTS')" />
    </template>

    <section class="article-container">
      <EventListComponent ref="eventListComponent" mode="article" :size="16" />
    </section>
  </f7-page>
</template>

<script>
import { defineComponent, ref, defineAsyncComponent } from "vue";
import { useStore } from "@/store";

// import CommonNavigationComponent from "@/components/navigations/CommonNavigationComponent.vue";
// import EventListComponent from "@/components/EventListComponent.vue";

const CommonNavigationComponent = defineAsyncComponent(() => import(/* webpackChunkName: "common-nav" */ /* webpackMode: "lazy" */ "@/components/navigations/CommonNavigationComponent.vue"));
const EventListComponent = defineAsyncComponent(() => import(/* webpackChunkName: "event-list" */ /* webpackMode: "lazy" */ "@/components/EventListComponent.vue"));

export default defineComponent({
  name: "EventsPage",
  components: {
    CommonNavigationComponent,
    EventListComponent,
  },
  props: { f7router: Object },
  setup() {
    const store = useStore();
    
    const EventListComponent = ref(false);
    const showPreloader = ref(false);

    return { EventListComponent, showPreloader };
  },
  methods: {
    async loadMore() {
      this.showPreloader = true;
      await this.$refs.eventListComponent.loadMore();
      this.showPreloader = false;
    },
    onSearch(searchValue) {
      this.$refs.eventListComponent.search(searchValue);
    },
  },
});
</script>
<style>
.article-container {
  margin-top: 0px;
}
.article-card .image {
  margin-top: 0px;
}
</style>
